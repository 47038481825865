import * as React from "react";
import {BrowserRouter,Routes,Route,Navigate,} from "react-router-dom";
import "./App.css";
import Detail from "./components/detail/Detail";
import ExpertDetail from "./components/detail/ExpertDetail/ExpertDetail";
import StoriesDetail from "./components/detail/stories/StoriesDetail";
import Home from "./components/home/Home";
import Listing from "./components/listing/Listing";
import ListingMap from "./components/listing/ListingMap";
import Map from "./components/listing/Map";
import MyAccount from "./components/myaccount/MyAccount";
import MyAccountEdit from "./components/myaccount/edit/MyAccountEdit";
import Login from "./components/pages/Login";
import Registration from "./components/pages/Registration";
import Settings from "./components/pages/Settings";
import ForgotPassword from "./components/pages/ForgotPassword";
import ViewAllStories from "./components/detail/stories/ViewAllStories";
import { cookiesss } from "./components/constants/Cookees"; //import axios config
import CommuniteesDetail from "./components/detail/Communitees/CommuniteesDetail";
import Groups from "./components/detail/Communitees/Groups";

function App() {

  return (
    <div id="wrapper">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Detail/:id" element={<Detail />} />
        <Route path="/Login" element={
          cookiesss ? (
            <Navigate to="/" />
          ) : (
            <Login />
          )
        } />
        <Route path="/Registration" element={
          cookiesss ? (
            <Navigate to="/" />
          ) : (
          <Registration />
          )
          } />
        <Route path="/ForgotPassword" element={
          cookiesss ? (
            <Navigate to="/" />
          ) : (
          <ForgotPassword />
          )
          } />
        <Route path="/Listing" element={<Listing />} />
        <Route path="/ListingMap" element={<ListingMap />} />
        <Route path="/Map" element={<Map />} />
        <Route path="/ExpertDetail/:id" element={<ExpertDetail />} />
        <Route path="/Settings" element={<Settings />} />
        <Route path="/MyAccount" element={
          cookiesss ? (
            <MyAccount />
            ) : (
              <Navigate to="/Login" />
            )
          } />
        <Route path="/MyAccountEdit/:id" element={<MyAccountEdit />} />
        <Route path="/StoriesDetail/:id" element={<StoriesDetail />} />
        <Route path="/ViewAllStories" element={<ViewAllStories />} />
        <Route path="/CommuniteesDetail/:id" element={<CommuniteesDetail />} />
        <Route path="/Groups/" element={<Groups />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}


function AuthRoute({ children, redirectTo }) {
  return localStorage.getItem("data") ? (
    <Navigate to={redirectTo} />
  ) : (
    children
  );
}

function ProtectedRoute({ children, redirectTo }) {
  return localStorage.getItem("data") ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
}
export default App;