import React from "react";
import Header from "../header/Header";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MyAccountPersonalinfo from "./MyAccountPersonalinfo";
import MyAccountManageListing from "./MyAccountManageListing";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid, createTheme, ThemeProvider } from "@mui/material";
import MyAccountCommunityList from "./Communities/MyAccountCommunityList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function MyAccount() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

   
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    palette: {
      primary: {
        light: '#B5AC68',
        main: "#1E3C55",
        dark: '#7C7C64',
        contrastText: '#fff',
      },
      secondary: {
        light: '#949866',
        main: '#7C7C64',
        dark: '#1E3C55',
        contrastText: '#000',
      },
    },
  });

  return (
    <div>
      <Header />
      <Box sx={{ bgcolor: "#FBFBFB", pt: "120px", pb: "48px" }}>
        <Container>
          <ThemeProvider theme={theme}>
            <Grid container item spacing={0}>
              <Grid item xs={12} md={3} lg={3} mt={3}>
                <Card>
                  <CardContent>
                    <Tabs
                      className="MyAccountEditTabs"
                      orientation="vertical"
                      fullWidth
                      variant="scrollable"
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs example"
                    >
                      <Tab
                        className="tabac"
                        label=" Personal info"
                        {...a11yProps(0)}
                      />
                      <Tab className="tabac" label="Pages" {...a11yProps(1)} />
                      <Tab
                        className="tabac"
                        label="Community List"
                        {...a11yProps(2)}
                      />
                      {/* <Tab
                        className="tabac"
                        label="Contributions"
                        {...a11yProps(2)}
                      />
                      <Tab
                        className="tabac"
                        label="Communitees"
                        {...a11yProps(3)}
                      />
                      <Tab
                        className="tabac"
                        label=" Orders"
                        {...a11yProps(4)}
                      /> */}
                    </Tabs>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <TabPanel value={value} index={0}>
                  <MyAccountPersonalinfo />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={12}>
                      <MyAccountManageListing />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <MyAccountCommunityList />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <Box p={5} paddingBottom="105px" className="error">
                        <Typography variant="h6" color="primary">
                          <span className="error_text">No data</span> found.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={4}>
                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={12}>
                      {/* <MyOrders /> */}
                    </Grid>
                  </Grid>
                </TabPanel>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </Box>
    </div>
  );
}
