import React from "react";
import Footer from "../footer/Footer";
import Banner from "./Banner";
import CompaniesCarousel from "./CompaniesCarousel";
import ExploreNearby from "./ExploreNearby";
import IndividualsCarousel from "./IndividualsCarousel";
import IndustrialNewsCarousel from "./IndustrialNewsCarousel";
import OurMission from "./OurMission";
import StakeholdersCarousel from "./StakeholdersCarousel";
import CommuniteesCarousel from "./CommuniteesCarousel";

export default function Home() {
  return (
    <div>
      <Banner />
      <ExploreNearby />
      <OurMission />
      <CompaniesCarousel />
      <IndividualsCarousel />
      <IndustrialNewsCarousel />
      <StakeholdersCarousel />
      <CommuniteesCarousel />
      <Footer />
    </div>
  );
}
