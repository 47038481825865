import {Box,Button,Checkbox,Container,Stack,Avatar,Grid,Divider,
  Chip,FormControl,TextField,OutlinedInput,ThemeProvider,createTheme,Typography} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import logo112 from "../images/logo-icon.webp";
import Google from "../images/icons/social-google.svg";
import Facebook from "../images/icons/social-facebook.svg";
import axios from "../utils/Axios"; //import axios config
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Cookies from 'universal-cookie';

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const theme = createTheme({
  palette: {
    primary: {
      main: "#1e3c55",
    },
    secondary: {
      main: "#676767",
    },
    linkcolor: {
      main: "#013A4B",
    },
  },
});

// style constant
const useStyles = makeStyles((theme) => ({
  redButton: {
    fontSize: "1rem",
    fontWeight: 500,
    backgroundColor: theme.palette.grey[50],
    border: "1px solid",
    borderColor: theme.palette.grey[100],
    color: theme.palette.grey[700],
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  signDivider: {
    flexGrow: 1,
  },
  welcomeText: {
    fontFamily: "Merriweather, serif",
    fontWeight: 800,
    textAlign: "center",
    color: theme.palette.grey[600],
  },
  signText: {
    cursor: "unset",
    margin: theme.spacing(2),
    padding: "5px 56px",
    borderColor: `${theme.palette.grey[100]} !important`,
    color: `${theme.palette.grey[900]}!important`,
    fontWeight: 500,
  },
  loginIcon: {
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "8px",
    },
  },
  loginInput: {
    ...theme.typography.customInput,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  link: {
    color: "#013A4B",
  },
}));

export default function Login() {
  const cookies = new Cookies();
  const history = useNavigate();
  const [password, setPassword] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [showPassword2, setShowPassword] = React.useState(0);
  const [userMsg, setUserMsg] = React.useState(false);
  const [passwordMsg, setPasswordMsg] = React.useState(false);
  const [usertext, setUsertext] = React.useState("");
  const [passwordtext, setPasswordtext] = React.useState("");

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", values.password);
    formdata.append("showPassword", showPassword2);

    axios("Login/login", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (!response.data.error) {
          cookies.set('data', JSON.stringify(response.data.data.api_key), { path: '/', maxAge: 20*365*24*60*60 });
          localStorage.setItem(
            "data",
            JSON.stringify(response.data.data.api_key)
          );
          window.location.reload();
          //alert.success("Login Successfull");
        } else if (response.data.exist === 1) {
          //alert.error(response.data.message);

          setShowPassword(1);
        } else if (response.data.exist === 0) {
          setShowPassword(0);
          history({
            pathname: "/Registration",
            state: { data: true, email: username },
          });
        } else {
          //alert.error(response.data.message);
          if (response.data.errortype === 1) {
            setUserMsg(true);
            setUsertext(response.data.message);
          } else if (response.data.errortype === 2) {
            setPasswordMsg(true);
            setPasswordtext(response.data.message);
          } else {
            setUserMsg(false);
            setUsertext("");
            setPasswordMsg(true);
            setPasswordtext(response.data.message);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="#1e3c5544"
        p={1}
        sx={{ height: "100vh" }}
      >
        <Box p={1} bgcolor="white" borderRadius="8px">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Link to="/">
                <IconButton  
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                >
                  <Avatar alt="Saaeh logo" src={logo112} />
                </IconButton>
              </Link>
              <Box p={0} mb={0} className="MyAccountProfileBg">
                <Grid item xs={12}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                      >
                        <Typography
                          className={classes.welcomeText}
                          gutterBottom
                          color="primary"
                          variant="h4"
                          fontWeight="bold"
                          component="div"
                        >
                          Hi, Welcome Back
                        </Typography>
                        <Typography
                          variant="caption"
                          fontSize="16px"
                          textAlign="center"
                        >
                          Enter your credentials to continue
                        </Typography>

                        <Grid item xs={12}>
                          <IconButton>
                            <img src={Google} alt="google" width="20px" />
                          </IconButton>
                          <IconButton>
                            <img src={Facebook} alt="Facebook" width="20px" />
                          </IconButton>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} my={3}></Grid>
                <Divider>
                  <Chip label="OR" />
                </Divider>

                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box my={2}>
                    <Typography variant="subtitle1">
                      Sign in with Email address
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} my={3}>
                  <Box
                    className="log_text_box"
                    sx={{ flexGrow: 1, alignSelf: "center" }}
                  >
                    {showPassword2 === 0 ? (
                      <TextField
                        fullWidth
                        label="Email Address"
                        variant="outlined"
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                        onKeyDown={handleKeyDown}
                        value={username}
                        error={userMsg}
                        helperText={usertext}
                      />
                    ) : (
                      ""
                    )}
                    {showPassword2 === 1 ? (
                      <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <OutlinedInput
                          fullWidth
                          placeholder="Type your password"
                          id="filled-error-helper-text"
                          type={values.showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange("password")}
                          onKeyDown={handleKeyDown}
                          error={passwordMsg}
                          helperText="Incorrect entry2."
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Box>
                    <Checkbox {...label} defaultChecked /> Remember me
                  </Box>
                  <Box>
                    <ThemeProvider theme={theme}>
                      <Link to="/ForgotPassword">
                        <Typography
                          className={classes.link}
                          variant="subtitle1"
                          color="primary"
                          sx={{ textDecoration: "none" }}
                        >
                          Forgot Password?
                        </Typography>
                      </Link>
                    </ThemeProvider>
                  </Box>
                </Stack>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit}
                  className="primaryButton"
                >
                  Sign In
                </Button>
              </Box>

              <Box p={2}>
                <Link to="/Registration">
                  <Typography
                    className={classes.link}
                    gutterBottom
                    color="primary"
                    variant="p"
                    component="p"
                  >
                    Don't have an account?
                  </Typography>
                </Link>
              </Box>
            </div>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
