import React from "react";
import Footer from "../footer/Footer";
import { useNavigate,useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Header from "../header/Header";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Map from "./Map";
import axios from "../utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import LoadingOverlay from "react-loading-overlay";
import Pagination from "@mui/material/Pagination";
import { Container, Grid } from "@mui/material";

export default function ListingMap(props) {
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useNavigate();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [params] = useSearchParams();
  const [location, setLocation] = React.useState(
    params.get("location") ? params.get("location") : ""
  );
  const [type, setType] = React.useState(
    params.get("type") ? params.get("type").split(",") : 1
  );
  const [keyword, setKeyword] = React.useState(
    params.get("keyword") ? params.get("keyword") : ""
  );
  const [lists, setlists] = React.useState([]);
  const [typelists, setTypeList] = React.useState([]);
  const [citylan, setcitylan] = React.useState([]);
  const [citylon, setcitylon] = React.useState([]);

  React.useEffect(() => {
    education_list();
    typeList();
  }, [page, rowsPerPage, location, type]);

  const setJob_typeSelect = async (val) => {
    setType(val);
  };

  const education_list = () => {
    setLoading(true);
    axios(
      `Saahe/MapListing?page=${page}&per_page=${rowsPerPage}&location=${location}&keyword=${keyword}&type=${type}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        window.scrollTo(0, 0);
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setlists([]);
            setTotal(res.data.total);
          } else {
            setLoading(false);
            setlists(res.data.data);
            setTotal(res.data.total);
            if (res.data.data.length > 0) {
              setcitylan(res.data.data[0].city_lan);
              setcitylon(res.data.data[0].city_lon);
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  //method for getting recent jobs
  const typeList = () => {
    setLoading(true);
    axios(`Home/typeList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTypeList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const SaaheView = (id, type) => {
    if (type == 3) {
      history({
        pathname: `/ExpertDetail/${id}`,
      });
    } else {
      history({
        pathname: `/Detail/${id}`,
      });
    }
    //}
  };

  const listingView = (location) => {
    history({
      pathname: `/Listing`,
      search: `?location=${location}`,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Header />
      <Grid container spacing={3} pt={10}>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Container>
  <Typography variant="h6" gutterBottom component="div" pt={5}>
            {lists[0] && lists[0].no} in {" "} {lists[0] && lists[0].city}
          </Typography>

          <Typography
            variant="caption"
            color="GrayText"
            display="block"
            gutterBottom
          >
            List of Industrial Tourism in {" "}
             {lists[0] && lists[0].city}
          </Typography>
          <Box className="education_listing_btn_box" color="GrayText">
            <Stack
              spacing={1}
              direction="row"
              flexWrap="wrap"
              overflow="auto"
            >
              {typelists.map((value, index) => {
                return (
                  <Typography gutterBottom>
                    <Button
                      onClick={() => setJob_typeSelect(value.id)}
                      variant="outlined"
                    >
                      {value.title}
                    </Button>
                  </Typography>
                );
              })}
              <Typography gutterBottom>
                <Button onClick={() => listingView(location)}>Filter</Button>
              </Typography>
            </Stack>
          </Box>

          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            {lists.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  height: "62vh",
                  overflow: "auto",
                  mt: "10px",
                }}
              >
                {lists.map((value, index) => {
                  return (
                    <Box
                      className="EducationDetailNearby"
                      sx={{ maxWidth: 309 }}
                      p={1}
                      onClick={() => SaaheView(value.id, value.type)}
                    >
                      <CardMedia
                        component="img"
                        width="309"
                        height="215"
                        image={value.banner}
                        alt=" Business Education Trainer"
                      />
                      <Box p={1}>
                        <Typography
                          sx={{ mt: 1.5 }}
                          gutterBottom
                          variant="subtitle2"
                          component="div"
                        >
                          {value.title}
                        </Typography>
                        <Typography
                          sx={{ mb: 1.5 }}
                          variant="caption"
                          color="text.secondary"
                        >
                          {value.city}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  maxWidth: "670px",
                }}
              >
                <Box p={5} paddingBottom="105px" className="error">
                  <Typography variant="h6" color="primary">
                    <span className="error_text">No data</span> found.
                  </Typography>
                </Box>
              </Box>
            )}
          </LoadingOverlay>

          <Box p={2} display="flex" justifyContent="center">
            <Stack spacing={2}>
              <Pagination count={total} page={page} onChange={handleChange} />
            </Stack>
          </Box>
            </Container>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
 <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            <Map lists={lists} citylan={citylan} citylon={citylon} />
          </LoadingOverlay>
            </Grid>
          </Grid>

     
      <Footer />
    </div>
  );
}
