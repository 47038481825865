import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Map from "../listing/Map";
import axios from "../utils/Axios"; //import axios config
import Pagination from "@mui/material/Pagination";
import { styled, alpha } from "@mui/material/styles";
import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";


const Search = styled("div")(({ theme }) => ({
    position: "relative",
    border: "1px solid #ccc",
    fullWidth: true,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  }));
  
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));

const useStyles = makeStyles((theme) => ({
  TopUniversitiesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#F7F7F7",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "0.3s",
    "&:hover": {
     boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function DetailNearBy({saaheId,setSaaheId}) {
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const history = useNavigate();
  const [instituteLists, setInstituteList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [citylan, setcitylan] = React.useState([]);
  const [citylon, setcitylon] = React.useState([]);

  const [location, setLocation] = React.useState([]);
  const [type, setTtype] = React.useState([]);

  const [cities, setcities] = React.useState([]);
  const [tutors, settutors] = React.useState([]);
  const [searchlist, setSearchList] = React.useState([]);

  const locationfilterChange = (value) => {
    setLocation(value);
  };

  const tutorfilterChange = (value) => {
    setTtype(value);
  };

  console.log(location);

  React.useEffect(() => {
    NearbyList(saaheId);
    getcities();
    getTypes();
  }, [page, rowsPerPage, location, type, searchlist]);

  //method for getting recent jobs
  const NearbyList = (saaheId) => {
    setLoading(true);
    axios(
      `Saahe/nerabyList?saahe_id=${saaheId}&page=${page}&per_page=${rowsPerPage}&location=${location}&type=${type}&keyword=${searchlist}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTotal(res.data.total);
          setInstituteList(res.data.data); //to set response data to state
          if (res.data.data.length > 0) {
            setcitylan(res.data.data[0].city_lan);
            setcitylon(res.data.data[0].city_lon);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const InstituteView = (id) => {
    setSaaheId(id);
    history.push({
      pathname: `/Detail/${id}`,
    });
  };

  const classes = useStyles();


  const getcities = () => {
    setLoading(true);
    axios(`Home/popularCities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setcities(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getTypes = () => {
    setLoading(true);
    axios(`Saahe/get_izra_type`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          settutors(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
          <Grid item xs={12} p={2.5}>
          <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="h6" gutterBottom component="div">
                  Nearby
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}> 
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="grouped-select">
                    Select Category
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Select Category"
                       onChange={(e) => tutorfilterChange(e.target.value)}
                  >
                      <MenuItem value='0'>Select All</MenuItem>
                     {tutors.map((value, index) => {
                          return (
                            <MenuItem value={value.id}>{value.name}</MenuItem>
                          );
                        })} 
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="grouped-select">Select city</InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Select city"
                        onChange={(e) => locationfilterChange(e.target.value)}
                  >   
                      <MenuItem value='0'>Select All</MenuItem>
                     {cities.map((value, index) => {
                          return (
                            <MenuItem value={value.id}>{value.title}</MenuItem>
                          );
                        })} 
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => setSearchList(e.target.value)}
                  />
                </Search>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                height: "50vh",
                overflow: "auto",
                mt: "10px",
              }}
            >
          {instituteLists &&
                instituteLists.map((value, index) => {
                  return (
                    <Grid item xs={12} md={4}>
                    <Box
                    className="TopUniversities_box"
                    p={2}
                    onClick={() => InstituteView(value.id)}
                  >
                    <Card
                      className={classes.TopUniversitiesCard}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={value.banner}
                        alt={value.title}
                      />

                      <Box className="cp">
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                           
                              <Box
                                boxShadow={3}
                                component={"img"}
                                marginTop={-9.5}
                                borderRadius="50%"
                                width={100}
                                height={100}
                                src={value.logo}
                                alt=""
                                to="/home"
                              />
                          
                          </Box>
                          <Tooltip Tooltip title={value.title}>
                            <Typography
                              Tooltip
                              title={value.title}
                              className="text_bg"
                              sx={{ mt: 1.5 }}
                              gutterBottom
                              variant="subtitle2"
                              component="div"
                            >
                              {value.title}
                            </Typography>
                          </Tooltip>
                          <Typography
                          sx={{ mb: 1.5 }}
                          variant="caption"
                          color="text.secondary"
                        >
                          {value.city}
                        </Typography>
                  
                        </CardContent>
                      </Box>
                    </Card> 
                  </Box>
                </Grid>
                  );
                })}
                </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box p={2} display="flex" justifyContent="center">
                <Stack spacing={2}>
                  <Pagination count={total} page={page} onChange={handleChange} />
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
              <Box   className="map_box_Nearby">
                <Map lists={instituteLists} citylan={citylan} citylon={citylon} />
              </Box>
          </Grid>
      </Grid>
    </div>
  );
}
