import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import {Grid} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SendIcon from "@mui/icons-material/Send";
import { Box } from "@mui/material";
import axios from "../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function UserPost({posts,getGroupProfile}) {
  const [expanded, setExpanded] = React.useState(false);
  const [comment ,setComment] = React.useState("");
  const alert = useAlert();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  

  const sendReplay = (post_id) => {
    let formdata = new FormData();
    formdata.append("comment", comment);
    formdata.append("post_id",post_id);
    axios(`Saahe/add_post_comment`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getGroupProfile();
          setComment("");
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  }; 


  return (
    <div>
    {posts.map((data, index) => {
      return (
    <Card sx={index>0 ? {mt:2} : ""} >
      <CardHeader
        avatar={<Avatar aria-label="recipe"></Avatar>}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        image={data.images}
        title={data.title}
        subheader={data.created}
      />
      <CardMedia
        component="img"
        height="194"
        image={data.images}
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {data.title}
        </Typography>
      </CardContent>
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {data.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-comment">
              Write comment
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-comment"
              //   type={values.showC                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              omment ? 'text' : 'text'}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={4}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="Write comment" edge="end">
                    <SendIcon onClick={(e) => sendReplay(data.id)}/>
                  </IconButton>
                </InputAdornment>
              }
              label="Write comment"
            />
          </FormControl>
          {data.comments.map((data2) => (
          <Box sx={{ bgcolor: "#f5f5f5", p: 1, m: 1, borderRadius: "10px" }}>
            <CardHeader
              avatar={<Avatar aria-label="recipe"></Avatar>}
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={data2.fullname}
              subheader={data2.created_at}
            />
            <Typography variant="body2" color="text.secondary" sx={{ px: 2 }}>
              {data2.replay}
            </Typography>
          </Box>
          ))
            }
        </CardContent>
      </Collapse>
    </Card>
      )
          })}
    </div>
  );
}
