//import React from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import Cookies from 'universal-cookie';

export default function ExploreNearby() {
  const cookies = new Cookies();
  const history = useNavigate();
  const [locations, setLocations] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    locationList();
  }, []);

  //method for getting recent jobs
  const locationList = () => {
    setLoading(true);
    axios(`Home/locationList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setLocations(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const Lisiting = (id) => {
    history(`/Listing?location=${id}`);
  };

  return (
    <div style={{ width: "100%" }}>
      <Container maxWidth="xl">
        <Box pt={6} pb={6} m={0}>
          <Box width="100%">
            <Typography
              variant="body2"
              color="InactiveCaptionText"
              gutterBottom
              component="div"
            >
              Explore with Sa'aeh 
            </Typography>
            <Typography variant="h5" gutterBottom component="div" color="#1F3A57">
              Explore Nearby 
            </Typography>
          </Box>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            <Box
              className="Edu_Explore_box_bg"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {locations.map((value, index) => {
                return (
                  <Box
                    className="Edu_Explore_box"
                    display="flex"
                    width="328px"
                    p={1}
                    m={1}
                  >
                    <Box
                      onClick={() => Lisiting(value.id)}
                      className="Edu_Explore_bg"
                    >
                      <Box sx={{ maxWidth: 300, height: 90 }}>
                        <CardMedia
                          className="Edu_Explore_card"
                          component="img"
                          alt="Education Explore Dubai"
                          image={value.imgUrl}
                          title="Education Explore Dubai"
                        />
                      </Box>
                    </Box>
                    <Box
                      p={1}
                      flexGrow={1}
                      onClick={() => Lisiting(value.id)}
                    >
                      <Typography variant="h6" color="#1F3A57" gutterBottom>
                        {value.title}
                      </Typography>
                      <Typography variant="p" color="textPrimary" gutterBottom>
                        {value.count} Properties
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </LoadingOverlay>
        </Box>
      </Container>
    </div>
  );
}
