import React from "react";
import Header from "../header/Header";
import HomeStyle from "./Aqar.css";
import {
  Autocomplete,
  Box,    
  Button,
  CardMedia,
  Typography,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import HomeImageOne from "../images/sustainabilityBanner2.webp";
import { useNavigate } from "react-router-dom";
import axios from "../utils/Axios"; //import axios config

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    textshadow: "0 0 10px #ffffff",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
    textshadow: "0 0 10px #ffffff",
  },
};
theme.typography.h4 = {
  fontSize: "1.1rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
    textshadow: "0 0 10px #ffffff",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
    textshadow: "0 0 10px #ffffff",
  },
};

export default function Banner() {
  const history = useNavigate();
  const [keyword, setKeyword] = React.useState("");
  const [autoData, setAutoData] = React.useState([]);

  React.useEffect(() => {
    getSearchAutocomplete();
  }, [keyword]);

  const getSearchAutocomplete = () => {
    if (keyword && keyword != "") {
      axios(`Home/search_autocomplete_data?keyword=${keyword}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          setAutoData(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAutoData([]);
    }
  };

  const filterSearch = () => {
    history({
      pathname: `/Listing`,
      search: `?keyword=${keyword}`,
    });
  };

  const InstituteView = (id, type) => {
    history(`/Detail/${id}`);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: "1px solid #e5e0e0",
          marginTop: "4rem",
        }}
      >
        <CardMedia
          image={HomeImageOne}
          alt="green iguana"
          class="home_banner_bg"
          className="overlay"
        >
          <Header />
          <Box sx={{ m: "10% 10%" }}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box>
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color="#fff"
                    gutterBottom
                  >
                   Explore Sustainability
                  </Typography>
                </ThemeProvider>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box>
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h4"
                    color="#fff"
                    textshadow="0 0 10px #ffffff"
                    gutterBottom
                    component="strong"
                  >
                  In all open areas , such parks , beaches , mountains.
                  </Typography>
                </ThemeProvider>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                pt: 3,
              }}
            >
              <Box
                bgcolor="white"
                border="1px solid #EBEBEB"
                p={2}
                minWidth="40%"
                height="30px"
                borderRadius="10px"
                className="home_search_box"
              >
                <Autocomplete
                  size="small"
                  freeSolo
                  disableClearable
                  id="combo-box-demo"
                  options={autoData}
                  getOptionLabel={(option) => option.edu_title}
                  onKeyUp={(e) => setKeyword(e.target.value)}
                  onKeyPress={(e) => setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && filterSearch();
                  }}
                  /* onClick={(e) => {
                    setKeyword(e.target.value);
                    filterSearch();
                  }} */
                  onChange={(e, value) => setKeyword(value.edu_title)}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      onClick={() => {
                        InstituteView(option.edu_id, option.edu_type);
                      }}
                    >
                      {option.edu_title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      placeholder="Enter Keyword"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="10px"
                className="home_search_btn"
              >
                <Button
                  sx={{
                    color: "white",
                    background:
                      "linear-gradient(35deg, #0B0F13 30%, #0B0F13 80%)",
                    px: 3,
                    marginLeft: "8px",
                    borderRadius: "10px",
                    height: "60px",
                    boxShadow: "0px 3px 5px #ffffff",
                    //   BoxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
                  }}
                  onClick={filterSearch}
                >
                  <SearchIcon /> SEARCH
                </Button>
              </Box>
            </Box>
          </Box>
        </CardMedia>
      </Box>
    </div>
  );
}
